<template>
  <v-card>
    <v-card-title v-if="executionType == 'execution'">
      {{ execution['module']['name'] }}
    </v-card-title>
    <v-card-title v-else-if="executionType == 'workflow'">
      {{ execution['workflow'] }}
    </v-card-title>
    <v-card-subtitle>
      <v-row>
        <v-col cols="2">Registration</v-col>
        <v-col cols="2">{{ execution['created_at'] }}</v-col>
        <template v-if="execution['status'].toUpperCase() === 'CANCELED'">
          <v-icon>mdi-dots-horizontal</v-icon>
          <span class="pa-3">Execution is canceled...</span>
        </template>
        <template v-else>
          <template v-if="execution['begin_at']">
            <v-col cols="1">Start</v-col>
            <v-col cols="3">{{ execution['begin_at'] }}</v-col>

            <template v-if="execution['end_at']">
              <v-col cols="1">End</v-col>
              <v-col cols="3">{{ execution['end_at'] }}</v-col>
            </template>
            <template v-else>
              <v-progress-circular
                :size="25"
                indeterminate
              />
              <span class="pa-3">Execution started, waiting for results...</span>
            </template>
          </template>
          <template v-else>
            <v-icon>mdi-dots-horizontal</v-icon>
            <span class="pa-3">In the queue, waiting for execution...</span>
          </template>
        </template>
      </v-row>
      <v-row>
        <template v-if="executionType == 'workflow'">
          <v-col cols="auto">
            Memo: {{ execution['memo'] }}
          </v-col>
        </template>
      </v-row>
    </v-card-subtitle>
    <v-card-actions>
      <v-row>
        <v-col v-if="execution['status'] == 'COMPLETE'" cols="2">
          Generated Files
        </v-col>
        <v-col v-if="execution['status'] == 'COMPLETE'" cols="3">
          <v-row v-for="file in outputs" :key="file" no-gutters>
            <span
              class="downloadLink"
              @click="downloadOutputFile(file)"
            >
              {{ file }}
            </span>
          </v-row>
        </v-col>
        <v-col
          v-if="execution['status'] != 'COMPLETE' && execution['end_at']"
          cols="2"
        >
          Status
        </v-col>
        <v-col
          v-if="execution['status'] != 'COMPLETE' && execution['end_at']"
          cols="3"
        >
          {{ execution['status'] || 'Unknown' }}
        </v-col>

        <v-spacer />
        <template v-if="executionType == 'module'">
          <v-col cols="auto">
            <ExecutionInputs
              :execution-id="id"
              :execution-type="executionType"
            />
          </v-col>
        </template>
        <v-col v-if="isCancelableExecution()" cols="auto">
          <CancelExecutionButton
            button-class="dialog-button"
            :execution-id="execution['id']"
            :execution-type="executionType"
            @refresh="refresh"
          />
        </v-col>
        <v-col
          v-if="
            execution['end_at'] &&
              executionType == 'module' || 'workflow' &&
              currentUser.username === execution['executor'] &&
              !isLoading
          "
          cols="auto"
        >
          <v-btn @click="downloadWorkFolder">
            <v-icon dark light>mdi-download</v-icon>
            DL working folder
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import CancelExecutionButton from '@/components/CancelExecutionButton.vue';
import ExecutionInputs from '../components/ExecutionInputs.vue';

export default {
  name: 'ExecutionResultCard',
  components: {
    ExecutionInputs,
    CancelExecutionButton
  },
  props: {
    execution: {
      type: Object,
      required: true
    },
    executionType: {
      type: String,
      required: true
    },
    outputs: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    workflowId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      currentUser: this.$session.get('user'),
      listPage: this.executionType === 'module' ? 'ExecutionList' : 'WorkflowEditor'
    };
  },
  methods: {
    refresh() {
      this.$emit('refresh');
    },
    downloadOutputFile(file) {
      this.$emit('downloadOutputFile', file);
    },
    downloadWorkFolder() {
      this.$emit('downloadWorkFolder');
    },
    isCancelableExecution() {
      const isExecutor = (this.execution.executor === this.currentUser.username) ||
                          (this.execution.executor === this.currentUser.id);
      const isCancelableStatus = (this.execution.end_at === null) &&
                                (this.execution.status.toUpperCase() !== 'CANCELED');
      return isExecutor && isCancelableStatus;
    }
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin-top: 0.5rem;
}
.dialog-button {
  margin-bottom: 5px;
}
</style>
