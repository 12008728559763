<template>
  <v-container>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />

    <ModelRegistrationForm ref="child"
                           :parent-module="mlModule"
                           :readonly="readonly"
                           :is-update="true"
                           :is-version-up="isVersionUp"
                           @formValidated="formValidated"
                           @outputChanged="outputChanged"
    />
    <v-btn v-if="!readonly" color="primary" text @click="onSubmit">
      Update Model
    </v-btn>
  </v-container>
</template>

<script>
import Loading from 'vue-loading-overlay';
import router from '@/router';
import ModelRegistrationForm from '@/components/ModelRegistrationForm';
import { showSuccessDialog, showErrorDialog, showWarningDialog }
  from '@/mixins/utils';
import consts from '@/store/consts';

export default {
  name: 'ModelUpdate',
  components: {
    ModelRegistrationForm,
    'loading': Loading
  },
  data() {
    return {
      outputs: [{ 'value': '' }],
      compoundsFile: null,
      predictedFile: null,
      mlModule: null,
      readonly: false,
      isLoading: false,
      notAllowedStatuses: ['Creation in progress', 'Creation failed'],
      messages: {
        'Creation in progress': 'The model is being created.',
        'Creation failed': 'The model creation failed.'
      },
      isVersionUp: false,
      callBackPageName: this.$session.get('callBackPageName', 'UserModelList')
    };
  },

  mounted() {
    this.checkLoggedIn(this.$session);
    const self = this;
    this.api.getMLModuleWithVersionInfo(this.$route.params.id, function(m) {
      if (self.notAllowedStatuses.includes(m.status)) {
        showWarningDialog(
          'Model cannot be updated',
          self.messages[m.status],
          function() {
            router.push({ name: self.callBackPageName });
          }
        );
      } else {
        self.mlModule = m;
        self.readonly = self.mlModule.status === 'Verified';
        if (self.readonly) {
          self.$store.dispatch('title/updateTitle', 'Model Details');
        }
        self.isVersionUp = self.mlModule.next_version !== 2;
      }
    }, function(error) {
      console.log(error);
    });
  },
  destroyed() {
    // Don't forget to remove forced title when leaving the page
    this.$store.dispatch('title/updateTitle', null);
  },
  methods: {
    outputChanged(outputs) {
      this.outputs = outputs;
    },
    onSubmit() {
      this.$refs.child.validateForm();
    },
    formValidated(mlModule, files) {
      this.sanitizeModel(mlModule);
      const self = this;
      if (this.compoundsFile !== null && this.predictedFile !== null) {
        files.compounds_file = this.compoundsFile;
        files[this.outputs[0].value] = this.predictedFile;
      }

      self.isLoading = true;
      this.api.registerAIModel(mlModule, files, function(response) {
        self.isLoading = false;
        showSuccessDialog(
          'Your AI model has been updated.',
          'You have been redirected to your list of models.'
        );
        router.push({ name: self.callBackPageName });
      }, function(error) {
        self.isLoading = false;
        if (error.status === 400) {
          showErrorDialog(error.data);
        } else {
          showErrorDialog(
            'Something went wrong.',
            'Try again, or contact an administrator.'
          );
        }
      });
    },
    sanitizeModel(mlModule) {
      if (mlModule && !consts.PredAndUncertainTypes.includes(mlModule?.type)) {
        for (const attr of consts.PredictionModelFields) {
          mlModule[attr] = '';
        }
      }
    }
  }
};
</script>
