<template>
  <v-data-table
    class="elevation-1"
    :headers="visibleColumns"
    :items="filteredItems"
    :loading="isLoading"
    :options.sync="options"
    loading-text="ロード中..."
    no-data-text="データがありません。"
    :page-count="pageCount"
    :server-items-length="itemCount"
    multi-sort
    item-key="id"
    :items-per-page="itemsPerPage"
    fixed-header
    hide-default-footer
  >
    <template #top="{ pagination, options, updateOptions }">
      <div class="d-flex align-center justify-space-between">
        <v-checkbox
          v-if="columns.some((column) => column.value === 'structure')"
          v-model="columns.find((column) => column.value === 'structure').visible"
          label="Display structures"
        />
        <v-data-footer
          :pagination="pagination"
          :disable-items-per-page="noResultsToDisplay"
          :options="options"
          :items-per-page-options="itemsPerPageOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :show-first-last-page="true"
          @update:options="updateOptions"
        />
      </div>
    </template>
    <!-- <template #[`body.prepend`]>
      <tr>
        <td v-for="column of columns" :key="column.text">
          <v-text-field
            v-if="column.value !== 'structure'"
            v-model="filters[column.value]"
            :placeholder="column.value"
            dense
          />
        </td>
      </tr>
    </template> -->
    <template #[`item.structure`]="{ item }">
      <span v-html="item.structure" />
    </template>
  </v-data-table>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';

export default {
  name: 'SdfItemList',
  components: {},
  props: {
    compounds: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    preventOptionsUpdate: {
      type: Boolean,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    itemCount: {
      type: Number,
      required: true
    },
    noResultsToDisplay: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      columns: [],
      items: [],
      itemsPerPage:
        Number(localStorage.getItem('exec-res-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS.slice(0, 3),
      options: {},
      filteredItems: [],
      filters: {}
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter(column => column.visible);
    }
  },
  watch: {
    compounds: {
      handler() {
        const visibleColumns = this.visibleColumns;
        this.columns = [];
        if (this.compounds.length > 0) {
          for (const name in this.compounds[0]) {
            this.appendColumn(name);
            this.columns[this.columns.length - 1].visible = this.isVisibleColumn(
              visibleColumns, name
            );
          }
        }
        this.items = this.compounds.map((compound) => compound);
        this.filteredItems = this.compounds.map((compound) => compound);
      },
      deep: true
    },
    options: {
      handler() {
        if (!this.preventOptionsUpdate) {
          this.updateData();
        }
      },
      deep: true
    },
    filters: {
      handler: function() {
        this.filteredItems = this.items.filter((item) =>
          Object.entries(this.filters).every(([k, v]) =>
            v === '' || String(item[k]).includes(v)
          )
        );
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    appendColumn(name) {
      if (['mol'].includes(name)) {
        return;
      }
      if (name.startsWith('CHEMTS_')) {
        return;
      }
      this.columns.push({
        text: name,
        value: name,
        sortable: name !== 'structure',
        visible: true
      });
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('exec-res-ipp', pagination.itemsPerPage);
      }
    },
    updateData() {
      this.$emit('updateData', this.options);
    },
    isVisibleColumn(visibleColumns, name) {
      return visibleColumns.length === 0 || visibleColumns.some((c) => c.value === name);
    }
  }
};
</script>
