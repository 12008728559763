<template>
  <div v-if="execution">
    <ExecutionResultCard
      :execution="execution"
      :execution-type="'workflow'"
      :outputs="outputs"
      @downloadOutputFile="downloadOutputFile"
      @downloadWorkFolder="downloadWorkFolder"
      @refresh="observeExecution"
    />
    <WorkflowExecutionInputs :execution-id="id" />
    <div v-if="execution['status'] === 'COMPLETE'">
      <SdfItemList
        v-if="mainOutputFormat === 'sdf'"
        :compounds="mainContents"
        :is-loading="isLoading"
        :no-data-text="noDataText"
        :prevent-options-update="preventOptionsUpdate"
        :page-count="nbPages"
        :item-count="nbItems"
        :no-results-to-display="noResultsToDisplay"
        @setPreventOptionsUpdate="setPreventOptionsUpdate"
        @updateData="loadMainResult"
      />
      <CsvItemList
        v-else-if="mainOutputFormat === 'csv'"
        :csv-data="mainContents"
        :is-loading="isLoading"
        :no-data-text="noDataText"
        :prevent-options-update="preventOptionsUpdate"
        :page-count="nbPages"
        :item-count="nbItems"
        :no-results-to-display="noResultsToDisplay"
        @setPreventOptionsUpdate="setPreventOptionsUpdate"
        @updateData="loadMainResult"
      />
    </div>
  </div>
</template>

<script>
import CsvItemList from '@/components/CsvItemList';
import SdfItemList from '@/components/SdfItemList';
import ExecutionResultCard from '@/components/ExecutionResultCard';
import WorkflowExecutionInputs from '@/components/WorkflowExecutionInputs.vue';
import { showErrorDialog } from '@/mixins/utils';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import { downloadWorkFolder } from '@/mixins/api_utils';

export default {
  name: 'WorkflowResult',
  components: {
    CsvItemList,
    SdfItemList,
    ExecutionResultCard,
    WorkflowExecutionInputs
  },
  data() {
    return {
      id: this.$route.params.id,
      timer: null,
      execution: null,
      lastUpdated: null,
      mainContents: null,
      isLoading: true,
      outputs: [],
      mainOutputFormat: '',
      noResultsToDisplay: true,
      noDataText: '',
      nbPages: 0,
      nbItems: 0,
      preventOptionsUpdate: true,
      isMounted: false,
      activeController: null,
      dataTableOptions: {
        page: 1,
        itemsPerPage: Number(localStorage.getItem('exec-res-ipp')) ||
                      ITEMS_PER_PAGE_OPTIONS[0]
      }
    };
  },
  watch: {
    mainContents: {
      handler: function() {
      },
      deep: true
    }
  },
  async mounted() {
    this.checkLoggedIn(this.$session);
    this.observeExecution();
    await this.loadMainResult(this.dataTableOptions);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    updateExecution() {
      const self = this;
      this.api.getWorkflowExecution(this.id,
        function(execution) {
          self.lastUpdated = Date.now();
          self.execution = execution;
          if (execution.end_at ||
              execution.status.toUpperCase() === 'CANCELED') {
            clearInterval(self.timer);
            if (execution.status === 'COMPLETE') {
              self.setOutput();
              self.loadMainResult(self.dataTableOptions);
            }
          }
        }, function(error) {
          console.log(error);
        });
    },
    observeExecution() {
      this.updateExecution();
      this.timer = setInterval(this.updateExecution, 30 * 1000);
    },
    setOutput() {
      const steps = JSON.parse(this.execution.executions);
      this.outputs = steps[steps.length - 1].output_files;
      this.mainOutputFormat = this.outputs[0]?.split('.').pop();
    },
    // The dataTableOptions parameter is needed because it is emitted
    // from child components.
    async loadMainResult(dataTableOptions) {
      const self = this;
      self.isLoading = true;
      // Need a new controller every time a new request is sent
      if (this.activeController !== null) {
        this.activeController.abort();
      }
      this.activeController = new AbortController();
      const signal = this.activeController.signal;
      await this.api.loadWorkflowExecutionOutputContents(
        self.id,
        dataTableOptions,
        signal,
        function(mainContents) {
          if (self.mainOutputFormat === 'sdf') {
            self.mainContents = mainContents.results;
            self.noResultsToDisplay = self.mainContents.length === 0;
          } else if (self.mainOutputFormat === 'csv') {
            self.mainContents = mainContents.results;
            self.noResultsToDisplay = self.mainContents.items.length === 0;
          }
          self.preventOptionsUpdate = true;
          self.nbPages = mainContents.total_pages;
          self.nbItems = mainContents.count;
          self.isLoading = false;
        }, function(error) {
          console.log(error);
          self.isLoading = false;
        });
      this.preventOptionsUpdate = false;
    },
    downloadOutputFile(file) {
      this.api.downloadWorkflowExecutionFile(this.id, file,
        function(error) {
          showErrorDialog('Error', error.data);
        });
    },
    downloadWorkFolder() {
      this.isLoading = true;
      downloadWorkFolder(this.id, 'workflow_execution');
      this.isLoading = false;
    },
    setPreventOptionsUpdate(value) {
      this.preventOptionsUpdate = value;
    }
  }
};
</script>
