import Swal from 'sweetalert2';
import variables from '@/styles/variables.module.scss';
import { Kekule } from 'kekule';

export const convertToSentenceCase = (string) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1).replaceAll('_', ' ');
  } else return '';
};

export const camelToSnake = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeCaseParameters = (params) => {
  const parameters = {};
  Object.keys(params).forEach(key => {
    parameters[camelToSnake(key)] = params[key];
  });
  return parameters;
};

export const paramsSerializer = (params) => {
  // Sample implementation of query string building
  const paramStrings = [];
  Object.keys(params).forEach(key => {
    if (params[key]) {
      paramStrings.push(`${key}=${encodeURIComponent(params[key])}`);
    }
  });
  return paramStrings.join('&');
};

export const showConfirmDialog = ({
  title,
  html,
  approveCB, // function
  deniedCB, // function
  cancelButtonText = 'Cancel'
} = {}) => {
  Swal.fire({
    icon: 'question',
    title: title,
    html: html || '',
    width: calculateTextWidth(html),
    showCancelButton: true,
    cancelButtonText: cancelButtonText,
    confirmButtonText: 'Yes',
    showDenyButton: deniedCB !== undefined,
    denyButtonText: 'No',
    customClass: {
      cancelButton: 'order-1 right-gap',
      confirmButton: 'order-2',
      denyButton: 'order-3',
      content: 'text-left-important'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      approveCB();
    } else if (result.isDenied) {
      deniedCB();
    }
  });
};

export const showErrorDialog = (title, html) => {
  Swal.fire({
    icon: 'error',
    title: title || 'Error',
    html: html || '',
    showConfirmButton: true
  });
};

export const showSuccessDialog = (title, html) => {
  Swal.fire({
    icon: 'success',
    title: title || 'Success',
    html: html || '',
    showConfirmButton: true
  });
};

export const showWarningDialog = (title, html, callback) => {
  Swal.fire({
    icon: 'warning',
    title: title || 'Warning',
    html: html || '',
    showConfirmButton: true
  }).then(
    callback
  );
};

export const calculateTextWidth = (text) => {
  const temp = document.createElement('div');
  document.body.appendChild(temp);
  temp.style.font = 'serif';
  temp.style.fontSize = '18px';
  temp.style.height = 'auto';
  temp.style.width = 'auto';
  temp.style.minWidth = '300px';
  temp.style.position = 'absolute';
  temp.style.whiteSpace = 'no-wrap';
  temp.innerHTML = text;

  const width = Math.ceil(temp.clientWidth) + 100;

  document.body.removeChild(temp);
  return width + 'px';
};

export const truncTo = (val, n) => {
  return Math.trunc(val * Math.pow(10, n)) / Math.pow(10, n);
};

export const formatNumber = (value) => {
  if (Number.isInteger(Number(value))) {
    return parseInt(value);
  } else {
    const v = parseFloat(value);
    if (isNaN(v)) {
      return value;
    } else if (v < 1) {
      return v.toFixed(3);
    } else {
      return v.toFixed(2);
    }
  }
};

export const isEmpty = (value) => {
  return (
  // null or undefined
    (value == null) ||
    // has length and it's zero
    (Object.prototype.hasOwnProperty.call(value, 'length') &&
      value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const clone = (items) => items.map(
  item => Array.isArray(item) ? clone(item) : { ...item }
);

export const activityCssClass = (value, profile) => {
  if (profile.scalingType === 'none') {
    return 'profile-neutral';
  } else if (profile.scalingType === 'step') {
    if (value <= profile.value1) {
      return profile.invert ? 'profile-active' : 'profile-inactive';
    } else {
      return profile.invert ? 'profile-inactive' : 'profile-active';
    }
  } else if (profile.scalingType === 'threshold' ||
             profile.scalingType === 'sigmoid') {
    if (value <= profile.value1) {
      return profile.invert ? 'profile-active' : 'profile-inactive';
    } else if (value >= profile.value2) {
      return profile.invert ? 'profile-inactive' : 'profile-active';
    } else {
      return 'profile-neutral';
    }
  } else if (profile.scalingType === 'range') {
    if (value <= profile.value1 || value >= profile.value4) {
      return profile.invert ? 'profile-active' : 'profile-inactive';
    } else if (value >= profile.value2 && value <= profile.value3) {
      return profile.invert ? 'profile-inactive' : 'profile-active';
    } else {
      return 'profile-neutral';
    }
  }
};

export const tableHeightWithMargin = (margin) => {
  return `calc(100vh - ${variables.paginationRowHeight}
                     - ${variables.containerPaddingX}
                     - ${variables.appBarHeight}
                     - ${margin}px
                     - 1px)`; // buffer
};

/**
 * Replace space with underscore in file name
 * to avoid error in the backend.
 * @param {File} file - The file to be renamed.
 * @return {File|string} The renamed file, or the original input if it's not a File.
 * @example
 * // returns "file_name.csv"
 * renameFile("file name.csv")
 */
export const renameFile = (file) => {
  if (!(file instanceof File)) {
    return file;
  }

  const newName = file.name.replace(/\s/g, '_');
  return new File([file], newName, { type: file.type });
};

export const getSmilesListForEachAsteriskFromEditor = (asteriskCount, editorId) => {
  const compoundList = [];
  const composer = Kekule.Widget.getWidgetOnElem(
    document.getElementById(editorId)
  );

  const mols = composer.exportObjs(Kekule.Molecule);
  if (asteriskCount === 0) {
    // If there is no asterisk, there is a selection
    // It is already replaced by an asterisk
    // So just return the current compound
    return Kekule.IO.saveFormatData(mols[0], 'smi');
  }
  for (let i = 1; i <= asteriskCount; i++) {
    const mol = mols[0].clone();
    const nodeToremove = [];
    let count = 1;
    for (const node of mol.getNodes()) {
      if (node.CLASS_NAME === 'Kekule.Pseudoatom') {
        if (node.getSymbol() === '*') {
          if (count !== i) {
            nodeToremove.push(node);
          }
          count += 1;
        }
      }
    }
    for (const node of nodeToremove) {
      mol.removeNode(node);
    }
    compoundList.push(Kekule.IO.saveFormatData(mol, 'smi'));
  }
  return compoundList.join(',');
};
