import api from '@/api';
import { showErrorDialog } from '@/mixins/utils';

export const linkablePredictionModels = async(forUncertainty = false) => {
  let linkableModules = [];
  await api.getMLModules(
    function(modules) {
      linkableModules = modules;
    },
    function(error) {
      console.log(error);
    },
    {
      type: 'SingleFeaturePrediction,MultipleFeaturePrediction',
      linkable: true,
      for_uncertainty: forUncertainty
    }
  );
  return linkableModules;
};

export const linkableObjectiveServers = async() => {
  let linkableModules = [];
  await api.getMLModules(
    function(modules) {
      linkableModules = modules;
    },
    function(error) {
      console.log(error);
    },
    {
      type: 'ObjectiveServer',
      linkable: true
    }
  );
  return linkableModules;
};

export const modelLinkableModules = async(id) => {
  let linkableModules = [];
  await api.getModelLinkableModules(
    function(modules) {
      linkableModules = modules;
    },
    function(error) {
      console.log(error);
    },
    id
  );
  return linkableModules;
};

export const downloadWorkFolder = async(id, executionType) => {
  await api.downloadExecutionWorkingFolder(id, executionType,
    function() {},
    function(error) {
      if (error.status === 404) {
        showErrorDialog(
          'Error',
          'Work folder not found.'
        );
      } else {
        showErrorDialog(
          'Error',
          'Something went wrong. Contact an administrator.'
        );
      }
    });
};
