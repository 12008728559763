<template>
  <v-col>
    <template v-for="(input, index) in inputs">
      <v-card :key="index" outlined class="mb-1 pr-1">
        <v-card-text>
          <v-layout row align-center d-flex>
            <div style="width: 24px">
              <v-tooltip v-if="canCollapse(input) && input.collapsed" top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    @click="collapseExpand(input)"
                  >
                    mdi-arrow-expand-vertical
                  </v-icon>
                </template>
                <span>開く</span>
              </v-tooltip>
              <v-tooltip v-else-if="canCollapse(input)" top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    @click="collapseExpand(input)"
                  >
                    mdi-arrow-collapse-vertical
                  </v-icon>
                </template>
                <span>閉じる</span>
              </v-tooltip>
            </div>
            <v-col>
              <v-row :key="`row1_${index}`" no-gutters>
                <v-col class="4">
                  <v-text-field
                    :key="`name_${index}`"
                    v-model="input.name"
                    :label="`${label} name`"
                    :rules="[
                      v => !!v || 'The name of the input is required',
                      v => input.disabled || !reservedArgumentNames.includes(v) || 'This name cannot be used',
                      v => input.disabled || !inputArgumentNames.slice(0, index).includes(v.trim())
                        || 'This name is already used'
                    ]"
                    required
                    dense
                    :disabled="input.disabled || readonly"
                  />
                </v-col>
                <v-col
                  v-if="isCompoundsFile(input.name)"
                  class="4 mx-10"
                >
                  <v-select
                    v-model="input.file_type"
                    :items="['.sdf', '.csv']"
                    label="Accepted file types"
                    item-value="value"
                    item-text="label"
                    :rules="[
                      v => v && v.length > 0 || 'At least one of the file type must be selected'
                    ]"
                    validate-on-blur
                    multiple
                    required
                    dense
                    :disabled="readonly"
                  />
                </v-col>
                <v-col class="4 mx-10">
                  <v-select
                    v-model="input.type"
                    :items="inputTypes"
                    :label="`${label} type`"
                    item-value="value"
                    item-text="label"
                    :rules="[
                      v => !!v || 'The type of the input file is required'
                    ]"
                    required
                    dense
                    :disabled="input.disabled || readonly"
                    @change="changeInputType(index, input)"
                  />
                </v-col>
                <v-col class="4">
                  <v-text-field
                    v-if="
                      ![
                        'yaml', 'dictionary', 'list', 'file',
                        'json', 'list-files', 'select'
                      ].includes(input.type)
                    "
                    :key="`default_${index}`"
                    v-model="input.default"
                    :label="`${label} default value`"
                    :placeholder="placeholder(input)"
                    :rules="defaultValueRules(input)"
                    validate-on-blur
                    dense
                    :required="input.disabled"
                    :disabled="readonly"
                    @blur="formatDefault(input)"
                  />
                  <v-text-field
                    v-if="['yaml', 'json'].includes(input.type)"
                    :key="`filename_${index}`"
                    v-model="input.filename"
                    :label="`${label} filename to write config`"
                    :rules="[
                      v => !!v?.trim() || 'The filename of the input is required'
                    ]"
                    dense
                    :disabled="readonly"
                  />
                  <OptionSetupForSelectArgument
                    v-if="input.type === 'select'"
                    ref="optionsSelect"
                    :options="input.options"
                    :readonly="readonly"
                    @update="input.options = $event"
                  />
                </v-col>
              </v-row>
              <v-row :key="`row2_${index}`" no-gutters>
                <v-text-field
                  :key="`description_${index}`"
                  v-model="input.description"
                  :label="`${label} description`"
                  dense
                  :rules="[
                    v => !!v || 'The description of the input is required'
                  ]"
                  required
                  :disabled="readonly"
                />
              </v-row>
            </v-col>
          </v-layout>
          <RegistrationArgumentList
            v-if="['yaml', 'json'].includes(input.type)"
            v-show="!input.collapsed"
            ref="nested"
            :key="`params_${index}`"
            :inputs="input.parameters"
            :input-types="inputTypes"
            label="Property"
            :readonly="readonly"
            class="ml-8"
            @addInput="addParameter(index, input)"
            @addParameter="addParameter"
            @addElement="addElement"
            @changeInputType="changeInputType"
            @removeLastInput="removeLastInput"
            @collapseExpand="collapseExpand"
          />
          <RegistrationArgumentList
            v-else-if="['dictionary', 'list'].includes(input.type)"
            v-show="!input.collapsed"
            ref="nested"
            :key="`element_${index}`"
            :inputs="input.element"
            :input-types="inputTypes"
            label="Element"
            :readonly="readonly"
            class="ml-8"
            @addInput="addElement(index, input)"
            @addParameter="addParameter"
            @addElement="addElement"
            @changeInputType="changeInputType"
            @removeLastInput="removeLastInput"
            @collapseExpand="collapseExpand"
          />
        </v-card-text>
      </v-card>
    </template>
    <span v-if="!readonly">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            :disabled="!canAddInput"
            color="blue"
            dense
            v-bind="attrs"
            @click="addInput"
            v-on="on"
          >
            mdi-plus-circle
          </v-icon>
        </template>
        <span>Add one more {{ label }}</span>
      </v-tooltip>
    </span>
    <span v-if="!readonly">
      <v-tooltip v-if="canRemoveInput" top>
        <template #activator="{ on, attrs }">
          <v-icon
            color="red"
            dense
            v-bind="attrs"
            @click="removeLastInput(inputs)"
            v-on="on"
          >
            mdi-minus-circle
          </v-icon>
        </template>
        <span>Remove last {{ label }}</span>
      </v-tooltip>
    </span>
  </v-col>
</template>

<script>
import consts from '@/store/consts';
import OptionSetupForSelectArgument from '@/components/OptionSetupForSelectArgument.vue';

export default {
  name: 'RegistrationArgumentList',
  components: {
    OptionSetupForSelectArgument: OptionSetupForSelectArgument
  },
  props: {
    inputs: {
      type: Array,
      required: true
    },
    inputTypes: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Argument'
    },
    moduleType: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reservedArgumentNames: this.$reservedArgumentNames
    };
  },
  computed: {
    canAddInput: function() {
      if (this.inputs.length === 0) {
        return true;
      }
      const lastInput = this.inputs[this.inputs.length - 1];
      return (
        lastInput.name !== '' &&
        lastInput.type !== '' &&
        lastInput.description !== ''
      );
    },
    canRemoveInput: function() {
      return (
        this.inputs.length > (consts.MandatoryFieldsCount[this.moduleType] ?? 1)
      );
    },
    inputArgumentNames: function() {
      return this.inputs.map((input) => input.name.trim());
    }
  },
  methods: {
    isCompoundsFile(inputName) {
      return inputName === 'compounds_file';
    },
    changeInputType(index, input) {
      this.$emit('changeInputType', index, input);
    },
    addInput() {
      this.$emit('addInput');
    },
    addParameter(index, input) {
      this.$emit('addParameter', index, input);
    },
    addElement(index, input) {
      this.$emit('addElement', index, input);
    },
    removeLastInput(inputs) {
      this.$emit('removeLastInput', inputs);
    },
    canCollapse(input) {
      return ['dictionary', 'list', 'yaml', 'json'].includes(input.type);
    },
    collapseExpand(input) {
      this.$emit('collapseExpand', input);
    },
    placeholder(input) {
      if (input.type === 'smiles') {
        return 'c1ccccc1';
      } else if (input.type === 'list-strings') {
        return 'example1, example2, ...';
      } else if (input.type === 'list-numbers') {
        return '2.54, 1.8, ...';
      } else if (input.type === 'boolean') {
        return 'True / False';
      } else if (input.type === 'float') {
        return '0.042';
      } else if (input.type === 'integer') {
        return '123';
      } else if (input.type === 'string') {
        return 'example';
      } else {
        return null;
      }
    },
    formatDefault(input) {
      if (input.default) {
        if (input.default.startsWith('[')) {
          input.default = input.default.substring(1);
        }
        if (input.default.endsWith(']')) {
          input.default = input.default.slice(0, -1);
        }
      }
    },
    defaultValueRules(input) {
      if (input.disabled && input.type !== 'smiles') {
        return [v => !!v || 'A default value is required'];
      } else {
        return [
          v => {
            if (v) {
              if (input.type === 'boolean' &&
                                !['true', 'false'].includes(v.toLowerCase())) {
                return 'Must be True or False';
              } else if (input.type === 'list-numbers' &&
                        input.default.split(',').some(
                          value => !/^[-]?([1-9]\d*|0)(\.\d+)?$/.test(value))
              ) {
                return 'All values must be numeric';
              } else { return true; }
            } else { return true; }
          }
        ];
      }
    },
    validate() {
      return (this.$refs.nested || []).every(item => item.validate()) &&
        (this.$refs.optionsSelect || []).every(item => item.validate());
    }
  }
};
</script>

<style scoped>
.v-card__text{
    padding: 12px 16px 4px 16px;
}
</style>
