<template>
  <v-container>
    <v-dialog v-model="dialog" width="500">
      <MLMOverviewDisplay :model-id="moduleToDisplayID" />
    </v-dialog>
    <v-card v-if="workflow">
      <v-card-title>{{ workflow.name }}</v-card-title>
      <v-divider />
      <!-- eslint-disable-next-line vue/max-len -->
      <v-card-text class="description-block">{{ descriptionShown }}</v-card-text>
      <ReadMoreSwitch
        v-if="workflow.description.split('\n').length > 3"
        class="pa-4"
        @onClick="switchDescription"
      />
    </v-card>
    <h2 class="header">Steps</h2>
    <v-stepper v-if="process.length > 0" v-model="step">
      <v-stepper-header>
        <template v-for="p in process">
          <v-divider v-if="p.id > 1" :key="`step_devider_${p.id}`" />
          <v-stepper-step
            :key="`step_${p.id}`"
            :step="p.id"
            :complete="p.valid"
          >
            Step {{ p.id }}
            <small>
              {{ p.module.name }}
              <v-icon
                v-show="p.module"
                color="primary"
                @click="displayModelInfo(p.module.id)"
              >
                mdi-information-outline
              </v-icon>
            </small>
          </v-stepper-step>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          v-for="p in process"
          :key="`step_content_${p.id}`"
          :step="p.id"
        >
          <ProcessStep
            :index="p.id"
            :all-params="parameterList"
            :process="process"
            :first-step="p.id === 1"
            :last-step="p.id === process.length"
            :module="p.module"
            :is-submitting="isSubmitting"
            @stepSubmit="stepSubmit"
            @stepBack="stepBack"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import router from '@/router';
import MLMOverviewDisplay
  from '@/components/design/Project/MLMOverviewDisplay';
import ProcessStep from '@/components/ProcessStep.vue';
import ReadMoreSwitch from '@/components/ReadMoreSwitch';
import { showErrorDialog, showSuccessDialog } from '@/mixins/utils';

export default {
  name: 'WorkflowExecute',
  components: {
    ProcessStep: ProcessStep,
    ReadMoreSwitch,
    MLMOverviewDisplay
  },
  data() {
    return {
      id: this.$route.params.id,
      workflow: null,
      process: [],
      parameterList: [],
      files: {},
      memo: '',
      step: 1,
      showFullDescription: false,
      dialog: false,
      moduleToDisplayID: null,
      visibility: '',
      group: '',
      isSubmitting: false
    };
  },
  computed: {
    descriptionShown() {
      return this.showFullDescription
        ? this.workflow.description
        : this.workflow.description.split('\n', 3).join('\n');
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    const self = this;
    this.api.getWorkflow(
      this.id,
      function(workflow) {
        const { name, description, config } = workflow;
        self.workflow = { name, description };
        try {
          const steps = config.steps;
          self.process = steps.map((step) => {
            const module = workflow.modules.find(
              (m) => m.id === step.module_id
            );
            return {
              id: step.num,
              module: module,
              valid: false
            };
          });
        } catch (error) {
          console.log(error);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    validateParams() {
      return true;
    },
    save() {
      const self = this;
      this.isSubmitting = true;
      this.api.executeWorkflow(
        this.id,
        this.parameterList,
        this.files,
        this.memo,
        this.visibility,
        this.group,
        function(response) {
          showSuccessDialog('Execution Registered');
          router.push({ name: 'WorkflowList' });
        },
        function() {
          self.isSubmitting = false;
          showErrorDialog(
            'An unexpected error occurred',
            'Try again or let an administrator know if the problem persists.'
          );
        }
      );
    },
    stepSubmit(processId, parameters, memo, files, visibility, group) {
      const processIndex = processId - 1;
      const currProcess = this.process[processIndex];
      this.parameterList.push(parameters);
      this.memo = memo;
      this.visibility = visibility;
      this.group = group;
      for (const key in files) {
        this.files[`${key}_${processIndex}`] = files[key];
      }
      if (this.validateParams()) {
        currProcess.valid = true;
        if (this.step !== this.process.length) {
          this.step++;
        } else {
          this.save();
        }
      } else {
        showErrorDialog(
          'Validation Error',
          'Validation failed.'
        );
      }
    },
    stepBack(processId) {
      this.step--;
      this.parameterList.pop();
      this.process[processId - 2].valid = false;
    },
    switchDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    displayModelInfo(modelId) {
      this.moduleToDisplayID = modelId;
      this.$nextTick(() => {
        this.dialog = true;
      });
    }
  }
};
</script>
