<template>
  <nav>
    <v-app-bar app color="primary" style="z-index:99" dark dense>
      <v-app-bar-nav-icon @click.stop="navDrawer = !navDrawer" />
      <v-toolbar-title>
        <slot name="title">{{ title }}</slot>
      </v-toolbar-title>
      <v-btn icon absolute right @click="loadManual">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="navDrawer"
      app width="200"
      style="z-index:100"
    >
      <v-list nav dense>
        <v-list-item
          v-for="(menu, i) in mainMenuList"
          :key="`main_${i}`"
          :to="menu.linkTo"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
          v-for="(menu, i) in developerMenuList"
          :key="`dev_${i}`"
          :to="menu.linkTo"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
          v-for="(menu, i) in toolsMenuList"
          :key="`tool_${i}`"
          :to="menu.linkTo"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template #append>
        <v-divider />
        <v-list dense>
          <v-list-item class="v-list-item v-list-item--link theme--light">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <Logout />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import Logout from '@/components/Logout';

export default {
  name: 'Menu',
  components: {
    Logout: Logout
  },
  data() {
    return {
      navDrawer: null,
      mainMenuList: [
        { linkTo: '/', title: 'Dashboard', icon: 'mdi-home' },
        {
          linkTo: '/ai',
          title: 'List of AI models',
          icon: 'mdi-head-dots-horizontal-outline'
        },
        {
          linkTo: '/workflows',
          title: 'List of Workflows',
          icon: 'mdi-stack-overflow'
        },
        {
          linkTo: '/executions',
          title: 'List of Executions',
          icon: 'mdi-table'
        }
      ],
      developerMenuList: [
        { linkTo: '/models', title: 'My AI models', icon: 'mdi-view-list' },
        {
          linkTo: '/jobs',
          title: 'My jobs',
          icon: 'mdi-stop-circle-outline'
        }
      ],
      toolsMenuList: [
        {
          linkTo: '/projects',
          title: 'プロジェクト一覧',
          icon: 'mdi-format-list-text'
        }
      ]
    };
  },
  computed: {
    title: function() {
      // TODO: Use better logic
      return this.$store.getters['title/title'] ?? this.$route.meta.title;
    }
  },
  mounted() {
    const token = this.$session.get('token');
    const self = this;
    this.api.getUserInfo(token, function(response) {
      const user = response.data;
      self.$session.set('user', user);
      self.addGroupManagementMenu(user);
      self.addStaffModelListMenu(user);
    });
  },
  methods: {
    loadManual() {
      this.api.downloadManual(
        function(error) {
          console.log(error);
        },
        this.$route.meta.manualPage
      );
    },
    addGroupManagementMenu(user) {
      if (user.owned_groups.length > 0) {
        this.toolsMenuList.push({
          linkTo: '/groups',
          title: 'グループ管理',
          icon: 'mdi-account-group'
        });
      }
    },
    addStaffModelListMenu(user) {
      if (user.is_staff) {
        this.toolsMenuList.push({
          linkTo: '/model-management',
          title: 'モデル管理',
          icon: 'mdi-database-edit-outline'
        });
      }
    }
  }
};

</script>
