<template>
  <v-container>
    <div v-if="execution">
      <ExecutionResultCard
        :execution="execution"
        :execution-type="'module'"
        :outputs="outputs"
        @downloadOutputFile="downloadOutputFile"
        @downloadWorkFolder="downloadWorkFolder"
        @refresh="observeExecution()"
      />

      <div v-if="execution['status'] == 'COMPLETE'">
        <SdfItemList
          v-if="outputs[0].split('.').pop()==='sdf'"
          :compounds="mainContents"
          :is-loading="isTableLoading"
          :no-data-text="noDataText"
          :prevent-options-update="preventOptionsUpdate"
          :page-count="nbPages"
          :item-count="nbItems"
          :no-results-to-display="noResultsToDisplay"
          @setPreventOptionsUpdate="setPreventOptionsUpdate"
          @updateData="loadMainResult"
        />
        <CsvItemList
          v-else-if="outputs[0].split('.').pop()==='csv'"
          :csv-data="mainContents"
          :is-loading="isTableLoading"
          :no-data-text="noDataText"
          :prevent-options-update="preventOptionsUpdate"
          :page-count="nbPages"
          :item-count="nbItems"
          :no-results-to-display="noResultsToDisplay"
          @setPreventOptionsUpdate="setPreventOptionsUpdate"
          @updateData="loadMainResult"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import SdfItemList from '@/components/SdfItemList';
import CsvItemList from '@/components/CsvItemList';
import ExecutionResultCard from '@/components/ExecutionResultCard';
import { showErrorDialog } from '@/mixins/utils';
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import { downloadWorkFolder } from '@/mixins/api_utils';

export default {
  name: 'ExecutionView',
  components: {
    SdfItemList,
    CsvItemList,
    ExecutionResultCard
  },
  data() {
    return {
      id: this.$route.params.id,
      timer: null,
      execution: null,
      config: null,
      lastUpdated: null,
      outputs: [],
      mainContents: null,
      isLoading: true,
      isTableLoading: true,
      currentUsername: this.$session.get('user').username,
      mainOutputFormat: '',
      noResultsToDisplay: true,
      noDataText: '',
      nbPages: 0,
      nbItems: 0,
      preventOptionsUpdate: true,
      isMounted: false,
      activeController: null,
      dataTableOptions: {
        page: 1,
        itemsPerPage: Number(localStorage.getItem('exec-res-ipp')) ||
            ITEMS_PER_PAGE_OPTIONS[0]
      }
    };
  },
  async mounted() {
    this.checkLoggedIn(this.$session);
    await this.observeExecution();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    updateExecution() {
      const self = this;
      self.api.getExecution(self.id, function(execution) {
        self.lastUpdated = Date.now();
        self.execution = execution;
        self.config = JSON.parse(self.execution.module.config);
        if (execution.end_at ||
            execution.status.toUpperCase() === 'CANCELED') {
          clearInterval(self.timer);
          self.isLoading = false;
          if (execution.status === 'COMPLETE') {
            self.outputs = self.config.output;
            self.outputs[0] = self.execution.output;
            self.loadMainResult(self.dataTableOptions);
          }
        }
      }, function(error) {
        console.log(error);
      });
    },
    observeExecution() {
      this.updateExecution();
      this.timer = setInterval(this.updateExecution, 10 * 1000);
    },
    // The dataTableOptions parameter is needed because it is emitted
    // from child components.
    async loadMainResult(dataTableOptions) {
      const mainOutputFileExt = this.execution.output;
      const self = this;
      self.isLoading = true;
      // Need a new controller every time a new request is sent
      if (this.activeController !== null) {
        this.activeController.abort();
      }
      this.activeController = new AbortController();
      const signal = this.activeController.signal;
      await this.api.getExecutionOutput(
        self.id,
        dataTableOptions,
        signal,
        function(responseData) {
          if (mainOutputFileExt.endsWith('.sdf')) {
            self.mainContents = responseData.results;
            self.noResultsToDisplay = self.mainContents.length === 0;
          } else if (mainOutputFileExt.endsWith('.csv')) {
            self.mainContents = responseData.results;
            self.noResultsToDisplay = self.mainContents.items.length === 0;
          }
          self.preventOptionsUpdate = true;
          self.nbPages = responseData.total_pages;
          self.nbItems = responseData.count;
          self.isTableLoading = false;
        }, function(error) {
          self.isTableLoading = false;
          console.log('error');
          return error;
        });
      this.preventOptionsUpdate = false;
    },
    downloadOutputFile(file) {
      this.api.downloadExecutionFile(this.id, file, function(error) {
        showErrorDialog('Error', error.data);
      });
    },
    downloadWorkFolder() {
      this.isLoading = true;
      downloadWorkFolder(this.id, 'executions');
      this.isLoading = false;
    },
    setPreventOptionsUpdate(value) {
      this.preventOptionsUpdate = value;
    }
  }
};
</script>
