<template>
  <v-container>
    <v-row>
      <v-card v-for="(item, i) in processCounts"
              :key="`process_${i}`"
              class="mr-1 mb-1"
      >
        <v-col class="pa-0">
          <v-card-title class="justify-center">
            {{ item }}
          </v-card-title>
          <v-card-subtitle>
            {{ processHeaders[i] }}
          </v-card-subtitle>
        </v-col>
      </v-card>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="indexedItems"
          :loading="isTableLoading"
          loading-text="ロード中..."
          no-data-text="データがありません。"
          item-key="subId"
          :items-per-page="itemsPerPage"
          :height="tableHeight"
          fixed-header
          hide-default-footer
          sort-by="created_at"
          sort-desc
          multi-sort
          @pagination="onPaginationChange"
        >
          <template #top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              :items-per-page-options="itemPerPageOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              :show-first-last-page="true"
              @update:options="updateOptions"
            />
          </template>
          <template #[`item.name`]="{ item }">
            <a :href="item.columns.url">
              {{ item.columns.idField }}
            </a>
          </template>
          <template #[`item.status`]="{ item }">
            <div v-if="item.end_at !== null">{{ item.status }}</div>
            <div v-else-if="item.begin_at !== null">Running</div>
            <div v-else>In the queue</div>
          </template>
          <template #[`item.action`]="{ item }">
            <CancelExecutionButton
              v-if="isCancelableExecution(item)"
              :execution-id="item.id"
              :execution-type="item.type"
              :name="item.columns.dialog"
              @refresh="refreshJobStatus"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import {
  tableHeightWithMargin,
  showErrorDialog
} from '@/mixins/utils';
import CancelExecutionButton from '@/components/CancelExecutionButton';

export default {
  name: 'JobsTable',
  components: {
    CancelExecutionButton
  },
  data() {
    return {
      type: '',
      isTableLoading: true,
      itemsPerPage:
        Number(localStorage.getItem('jobs-ipp')) ||
        ITEMS_PER_PAGE_OPTIONS[0],
      itemPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      executions: [],
      processCounts: {},
      processHeaders: {
        'working': 'Running',
        'maximum': 'Max Jobs',
        'waiting': 'Waiting',
        'complete': 'Completed'
      },
      headers: [
        { text: 'Job', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Begin datetime', value: 'begin_at' },
        { text: 'Action', value: 'action', sortable: false }
      ]
    };
  },
  computed: {
    tableHeight: function() {
      return tableHeightWithMargin(90);
    },
    indexedItems: function() {
      return this.executions.map((item, index) => ({
        subId: index,
        columns: this.identifyName(item.id, item.type, item.module_id || item.project_design_id),
        ...item
      }));
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.getJobsList();
    this.processExecutionStatus();
  },
  methods: {
    isCancelableExecution(item) {
      const isCancelableStatus =
        item.end_at === null && item.status !== 'Canceled';
      return isCancelableStatus;
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('jobs-ipp', pagination.itemsPerPage);
      }
    },
    getJobsList() {
      const self = this;
      this.api.getJobsList(
        function(executions) {
          self.executions = executions;
          self.isTableLoading = false;
        },
        function() {
          self.isTableLoading = false;
        }
      );
    },
    processExecutionStatus() {
      const self = this;
      this.api.getProcessExecutionStatus(
        function(executions) {
          self.processCounts = executions;
        },
        function(e) {
          showErrorDialog(
            'Failed to get the execution status',

            `Try again or let an administrator
                          know if the problem persists.`
          );
        }
      );
    },
    identifyName(id, type, sourceId = '') {
      const columns = {};
      if (type === 'module') {
        columns.idField = `Single execution #${id}`;
        columns.dialog = `Single execution #${id}`;
        columns.url = `/executions/${id}`;
      } else if (type === 'project_design') {
        columns.idField = `Project Design #${sourceId}`;
        columns.dialog = `Execution of Project Design #${sourceId}`;
        columns.url = `/project/design/${sourceId}`;
      } else if (type === 'workflow') {
        columns.idField = `Workflow execution #${id}`;
        columns.dialog = `Workflow execution #${id}`;
        columns.url = `/workflow/execution/${id}`;
      } else if (type === 'test') {
        columns.idField = `Test execution #${id}`;
        columns.dialog = `Test execution #${id}`;
        columns.url = `/models/${sourceId}/test`;
      } else if (type === 'profile_prediction') {
        columns.idField = `Project Design #${sourceId}`;
        columns.dialog = `Profile Prediction execution for Project Design #${sourceId}`;
        columns.url = `/project/design/${sourceId}`;
      }
      return columns;
    },
    refreshJobStatus() {
      this.getJobsList();
      this.processExecutionStatus();
    }
  }
};
</script>
