<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-row class="mx-5 mb-2">
      <v-btn
        v-if="designId != 0"
        @click="downloadPredictionResult"
      >
        <v-icon dark left>mdi-download</v-icon>
        予測結果ダウンロード
      </v-btn>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            class="mb-3 ml-5"
            color="primary"
            icon
            v-bind="attrs"
            v-on="on"
            @click="refresh"
          >
            <v-icon dark>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>リフレッシュ</span>
      </v-tooltip>
      <v-checkbox
        v-model="showCredibility"
        label="信頼性指標表示"
        class="ml-10 mt-0"
      />
    </v-row>

    <v-data-table
      v-model="references"
      :headers="headers"
      :items="compounds"
      disable-pagination
      item-key="key"
      multi-sort
      show-select
      class="elevation-1"
      hide-default-footer
      @item-selected="onReferencesSelected"
    >
      <template #[`header.data-table-select`]="{ on , props }">
        <div style="white-space: nowrap;">
          <p class="mb-0">参照</p>
          <v-simple-checkbox
            :ripple="false"
            style="display: inline-block;"
            v-bind="props"
            v-on="on"
          />
        </div>
      </template>
      <template #[`item.base`]="{ item }">
        <v-radio-group v-model="base" @change="onBaseChanged(item)">
          <v-radio :value="item.key" />
        </v-radio-group>
      </template>
      <template #[`item.structure`]="{ item }">
        <v-badge
          v-if="item.is_redrawn"
          content="カスタム"
          value="redrawn"
          bordered
          bottom
          color="rgb(153,0,153)"
          overlap
          offset-x="25"
          offset-y="20"
        >
          <span v-html="item.structure" />
        </v-badge>
        <span v-else v-html="item.structure" />
      </template>
      <template #[`item.profile`]="{ item }">
        <TargetProfileView
          :compound="item"
          :profile="profile"
          :show-credibility="showCredibility"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TargetProfileView from '@/components/design/TargetProfileView';
import { showErrorDialog } from '@/mixins/utils';

export default {
  name: 'CompoundList',
  components: {
    TargetProfileView
  },
  props: {
    baseCompound: {
      type: Object,
      default: () => null
    },
    compoundList: {
      type: Array,
      default: () => []
    },
    profile: {
      type: Array,
      default: () => []
    },
    designId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      columns: [],
      base: null,
      references: [],
      headers: [
        { text: '出発化合物', value: 'base', sortable: false, width: '70px' },
        {
          text: 'Reference',
          value: 'data-table-select',
          sortable: false
        },
        {
          text: '構造',
          value: 'structure',
          width: '150px',
          sortable: false,
          class: 'text-align'
        },
        { text: 'プロファイル', value: 'profile', sortable: false }
      ],
      showCredibility: false
    };
  },
  computed: {
    compounds: function() {
      return this.compoundList.map((compound, i) => {
        compound.key = i;
        compound.structure = compound.structure.replace(
          "width='300px'", "width='150px'"
        );
        return compound;
      });
    }
  },
  watch: {
    baseCompound: {
      handler() {
        if (this.base !== this.baseCompound.key) {
          this.base = this.baseCompound.key;
        }
      }
    },
    compoundList: {
      handler() {
        if (this.compoundList.length === 1) {
          // When there is only one compound, it is always the base compound
          // Key of compounds always starts from 0
          this.base = 0;
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    onBaseChanged(compound) {
      this.$emit('onBaseChanged', compound);
    },
    onReferencesSelected() {
      this.$nextTick(() => {
        this.$emit('onReferencesSelected', this.references);
      });
    },
    downloadPredictionResult() {
      const self = this;
      this.api.downloadPredictionResult(
        this.designId,
        function(response) {
          self.api.downloadFileHelper(response, 'prediction_results.sdf');
        },
        function(error) {
          console.log(error);
          showErrorDialog(
            '予想外のエラーが発生しました。',
            'もう一度実行するか、解決しない場合は管理者に知らせてください。'
          );
        }
      );
    },
    refresh() {
      this.$emit('refresh');
    }
  }
};
</script>

<style scoped>

:deep(.text-align) {
    text-align: center !important;
}
:deep(.theme--light.v-data-table tbody tr:hover td:not(.profile-cell)){
    background: white;
}
:deep(.theme--light.v-data-table tbody tr.v-data-table__selected){
    background: white;
}

:deep(.v-data-table .v-data-table__wrapper) {
  overflow: unset;
}
:deep(.v-data-table-header th) {
    position: sticky;
    top: 48px;
    z-index:20;
    background-color: white;
}
</style>
